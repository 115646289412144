import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';
import {
  InstantSearch,
  connectHits,
  connectStateResults,
  connectSearchBox,
} from 'react-instantsearch-dom';

import Hymn from './entityList/Hymn';
import Author from './entityList/Author';
import Composer from './entityList/Composer';
import Tune from './entityList/Tune';
import {
  HYMNS,
  AUTHORS,
  COMPOSERS,
  TUNES,
} from '../globals';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#ebebeb',
    marginTop: '10px',
    marginBottom: '15px',
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const Results = connectStateResults(({ searchState, children }) =>
  searchState && searchState.query ? children : null);

const SearchBox = ({ currentRefinement, refine, setSearchStr }) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={currentRefinement}
        onChange={({ currentTarget }) => {
          const { value } = currentTarget;
          setSearchStr(value);
          refine(value);
        }}
      />
    </div>
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string,
  refine: PropTypes.func.isRequired,
  setSearchStr: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  currentRefinement: undefined,
};

const CustomSearchBox = connectSearchBox(SearchBox);

const Hits = ({ hits, filterEntity }) => (
  <ul className="u-list-style-none">

    {hits
      .filter(({ type }) => {
        if (!filterEntity) return true;
        return type === filterEntity;
      })
      .map(({
        name,
        title,
        slug,
        type,
        birthDate,
        deathDate,
        author,
        composer,
      }, i, arr) => {
        const entity = {
          frontmatter: {
            name,
            title,
            slug,
            type,
            birthDate,
            deathDate,
          },
          author: {
            frontmatter: {
              name: author,
            },
          },
          composer: {
            frontmatter: {
              name: composer,
            },
          },
        };

        return (
          <li key={i}>

            {type === HYMNS && (
              <Hymn divider={i !== arr.length - 1} hymn={entity} />
            )}

            {type === AUTHORS && (
              <Author divider={i !== arr.length - 1} author={entity} />
            )}

            {type === COMPOSERS && (
              <Composer divider={i !== arr.length - 1} composer={entity} />
            )}

            {type === TUNES && (
              <Tune divider={i !== arr.length - 1} tune={entity} />
            )}

          </li>
        );
      })}

  </ul>
);

Hits.propTypes = {
  hits: PropTypes.array,
  filterEntity: PropTypes.string,
};

Hits.defaultProps = {
  hits: [],
  filterEntity: undefined,
};

const CustomHits = connectHits(Hits);

const Search = ({ filterEntity, setSearchStr }) => (
  <InstantSearch
    searchClient={searchClient}
    indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
  >
    <CustomSearchBox setSearchStr={setSearchStr} />
    <Results>
      <CustomHits filterEntity={filterEntity} />
    </Results>
  </InstantSearch>
);

Search.propTypes = {
  filterEntity: PropTypes.string,
  setSearchStr: PropTypes.func.isRequired,
};

Search.defaultProps = {
  filterEntity: undefined,
};

export default Search;
