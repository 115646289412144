import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import InfoPaper from './InfoPaper';
import Hymn from './Hymn';
import Author from './Author';
import Composer from './Composer';
import Tune from './Tune';
import { titlize } from '../../utils';
import {
  HYMNS,
  AUTHORS,
  COMPOSERS,
  TUNES,
} from '../../globals';
import Search from '../Search';

const Entities = ({
  entityName,
  entities,
  selectedEntity,
  hasInternet,
}) => {
  const [searchStr, setSearchStr] = useState('');

  const getContent = () => {
    if (entities.length > 0 && entities.length === 0) {
      return (
        <ListItem>
          <ListItemText primary={`No matching ${entityName}.`} />
        </ListItem>
      );
    }

    if (entities.length > 0) {
      return (
        <div className="entitylist__content">

          {entities.map((entity, i, arr) => {
            if (entity.frontmatter.type !== entityName) return null;

            switch (entityName) {
              case HYMNS: {
                const { author: authorName, tune: tuneName } = entity.frontmatter;
                const author = arr.find(({ frontmatter }) =>
                  frontmatter.name === authorName
                  && frontmatter.type === AUTHORS);

                const tune = arr.find(({ frontmatter }) =>
                  frontmatter.title === tuneName
                  && frontmatter.type === TUNES);

                const hymn = { ...entity, author, tune };

                return (
                  <Hymn
                    key={i}
                    divider={i !== arr.length - 1}
                    hymn={hymn}
                    selectedEntity={selectedEntity}
                  />
                );
              }
              case AUTHORS:
                return (
                  <Author
                    key={i}
                    divider={i !== arr.length - 1}
                    author={entity}
                    selectedEntity={selectedEntity}
                  />
                );
              case COMPOSERS:
                return (
                  <Composer
                    key={i}
                    divider={i !== arr.length - 1}
                    composer={entity}
                    selectedEntity={selectedEntity}
                  />
                );
              case TUNES: {
                const { composer: composerName } = entity.frontmatter;
                const composer = arr.find(({ frontmatter }) =>
                  frontmatter.name === composerName
                  && frontmatter.type === COMPOSERS);

                const tune = { ...entity, composer };

                return (
                  <Tune
                    key={i}
                    divider={i !== arr.length - 1}
                    tune={tune}
                    selectedEntity={selectedEntity}
                  />
                );
              }
              default:
                return null;
            }
          })}

        </div>
      );
    }

    if (!hasInternet) {
      return <InfoPaper warning="offline" />;
    }

    return <InfoPaper warning="noData" entityName={entityName} />;
  };

  return (
    <div className="entitylist">

      {entityName && (
        <Typography
          component="h2"
          variant="h4"
          className="entitylist__title"
        >
          {titlize(entityName)}
        </Typography>
      )}

      <Search filterEntity={entityName} setSearchStr={setSearchStr} />

      {!searchStr && getContent()}

    </div>
  );
};

Entities.propTypes = {
  entities: PropTypes.array,
  selectedEntity: PropTypes.object,
  entityName: PropTypes.string,
  hasInternet: PropTypes.bool.isRequired,
};

Entities.defaultProps = {
  entities: [],
  selectedEntity: undefined,
  entityName: undefined,
};

export default Entities;
