import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'gatsby';
import { ListItem, ListItemText } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { isMobile } from 'react-device-detect';

import { titlize } from '../../utils';

const HymnList = ({ hymn, divider, selectedEntity = {} }) => {
  const { title, slug, type } = get(hymn, 'frontmatter');
  const authorName = get(hymn, 'author.frontmatter.name');
  const selectedEntityType = get(selectedEntity, 'frontmatter.type');
  const selectedEntitySlug = get(selectedEntity, 'frontmatter.slug');
  const isSelected = selectedEntityType === type && selectedEntitySlug === slug;

  return (
    <ListItem
      dense
      divider={divider}
      button
      className={`entity-list-item${isSelected ? ' entity-list-item--selected' : ''}`}
    >
      <ListItemText primary={titlize(title)} secondary={`By ${titlize(authorName)}`} />

      {isMobile && <ChevronRightRoundedIcon className="u-txt--primary" />}

      <Link className="u-abs-cover" to={slug} />
    </ListItem>
  );
};

HymnList.propTypes = {
  hymn: PropTypes.object.isRequired,
  divider: PropTypes.bool,
  selectedEntity: PropTypes.object,
};

HymnList.defaultProps = {
  selectedEntity: {},
  divider: false,
};

export default HymnList;
