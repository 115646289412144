import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Link } from 'gatsby';
import {
  Typography,
  Grid,
  IconButton,
  Button,
  Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { isMobile } from 'react-device-detect';

import { titlize } from '../../utils';
import AudioPlayer from '../AudioPlayer';
import { AUTHORS, TUNES, HYMNS } from '../../globals';

const Display = ({ entityData, entities }) => {
  const {
    title,
    tune: tuneName,
    author: authorName,
    verses = [],
    content = [],
  } = get(entityData, 'frontmatter', {});

  const author = entities.find(({ frontmatter }) =>
    frontmatter.name === authorName
    && frontmatter.type === AUTHORS);

  const tunes = entities.filter(({ frontmatter }) => frontmatter.type === TUNES);
  const tune = tunes.find(({ frontmatter }) => frontmatter.title === tuneName) || {};

  const alternativeTunes = tunes.filter(({ frontmatter }) =>
    frontmatter.title !== tuneName
    && frontmatter.meter
    && frontmatter.meter === get(tune, 'frontmatter.meter'));

  const {
    bassVoice,
    altoVoice,
    combinedVoices,
    sopranoVoice,
    tenorVoice,
  } = get(tune, 'frontmatter', {});

  const parts = [
    ...sopranoVoice ? [{ name: 'soprano', path: sopranoVoice }] : [],
    ...altoVoice ? [{ name: 'alto', path: altoVoice }] : [],
    ...tenorVoice ? [{ name: 'tenor', path: tenorVoice }] : [],
    ...bassVoice ? [{ name: 'bass', path: bassVoice }] : [],
    ...combinedVoices ? [{ name: 'combined', path: combinedVoices }] : [],
  ];

  return (
    <Container>
      <Grid container spacing={3} className="u-margin-b">
        <Grid item xs={1} className="u-relative">

          {isMobile && (
            <Link className="entity-back" to={`/${HYMNS}`}>
              <IconButton>
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Link>
          )}

        </Grid>
        <Grid item xs={10}>
          <Typography
            component="h1"
            variant="h5"
            className="entity-title"
          >
            {titlize(title)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>

          {verses.map(({ lines }, i) => (
            <Grid key={i} container spacing={3} className="entity-verse">
              <Grid item xs={1}>
                <div className="entity-verse__number">{i + 1}</div>
              </Grid>
              <Grid item xs={10}>
                <ul className="u-list-style-none">

                  {lines.map((line, lineKey) => (
                    <li key={`${i}-${lineKey}`}>{line}</li>
                  ))}

                </ul>
              </Grid>
            </Grid>
          ))}

          {author && (
            <div className="entity-hymn__link u-margin-t">
              <div className="u-txt--semibold u-margin-b--sm">Author</div>
              <Link to={author.frontmatter.slug} className="no-underline">
                <Button
                  variant="outlined"
                  color="primary"
                >
                  {author.frontmatter.name}
                </Button>
              </Link>
            </div>
          )}

          {!isEmpty(tune) && (
            <div className="entity-hymn__link u-margin-t">
              <div className="u-txt--semibold u-margin-b--sm">Recommended Tune</div>
              <Link to={tune.frontmatter.slug} className="no-underline">
                <Button variant="outlined" color="primary">
                  {tune.frontmatter.title}
                </Button>
              </Link>
            </div>
          )}

          {alternativeTunes.length > 0 && (
            <div className="entity-hymn__link u-margin-t">
              <div className="u-txt--semibold u-margin-b--sm">Alternative Tunes</div>

              {alternativeTunes.map(({ frontmatter }, i) => (
                <div className="entity-hymn__alternative-tune">
                  <Link key={i} to={frontmatter.slug} className="no-underline">
                    <Button variant="outlined" color="primary">
                      {frontmatter.title}
                    </Button>
                  </Link>
                </div>
              ))}

            </div>
          )}

        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>

              {!isEmpty(parts) && (
                <>
                  <div className="u-txt--semibold u-margin-b--sm">Parts</div>
                  <ul className="u-list-style-none entity-hymn__parts">

                    {parts.map(({ name, path }, i) => (
                      <li className="entity-hymn__part" key={i}>
                        <AudioPlayer
                          title={titlize(name)}
                          src={path}
                        />
                      </li>
                    ))}

                  </ul>
                </>
              )}

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {content && content.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="u-margin-t">

              {content.map((p, i) => (
                <Typography component="p" key={i}>{p}</Typography>
              ))}

            </div>
          </Grid>
        </Grid>
      )}

    </Container>
  );
};

Display.propTypes = {
  entityData: PropTypes.object,
  entities: PropTypes.array,
};

Display.defaultProps = {
  entityData: {},
  entities: [],
};

export default Display;
