import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'gatsby';
import {
  Typography,
  Grid,
  IconButton,
  Button,
  Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { isMobile } from 'react-device-detect';

import { titlize, getDateText } from '../../utils';
import { HYMNS, AUTHORS } from '../../globals';

const Display = ({ entityData, entities }) => {
  const {
    name,
    birthDate,
    deathDate,
    content = [],
  } = get(entityData, 'frontmatter', {});

  const hymns = entities.filter(({ frontmatter }) =>
    frontmatter.type === HYMNS
    && frontmatter.author === name);

  const birthText = getDateText({ birthDate, deathDate });

  return (
    <Container>
      <Grid container spacing={3} className="u-margin-b">
        <Grid item xs={1} className="u-relative">

          {isMobile && (
            <Link className="entity-back" to={`/${AUTHORS}`}>
              <IconButton>
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Link>
          )}

        </Grid>
        <Grid item xs={10}>
          <Typography
            component="h1"
            variant="h5"
            className="entity-title"
          >
            {`${titlize(name)} ${birthText}`}
          </Typography>
        </Grid>
      </Grid>

      {content && content.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>

            {content.map((p, i) => <div className="paragraph" key={i}>{p}</div>)}

          </Grid>
        </Grid>
      )}

      {hymns.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="u-txt--semibold u-margin-b--sm u-margin-t--sm">Hymns Authored</div>
            <ul className="u-list-style-none">

              {hymns.map(({ frontmatter }, i) => (
                <li className="entity-author__hymn" key={i}>
                  <Link to={frontmatter.slug} className="no-underline">
                    <Button variant="outlined" color="primary">
                      {frontmatter.title}
                    </Button>
                  </Link>
                </li>
              ))}

            </ul>

          </Grid>
        </Grid>
      )}

    </Container>
  );
};

Display.propTypes = {
  entityData: PropTypes.object,
  entities: PropTypes.array,
};

Display.defaultProps = {
  entityData: {},
  entities: [],
};

export default Display;
