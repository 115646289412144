import {
  words,
  includes,
  upperFirst,
  padStart,
} from 'lodash';

export const titlize = (str = '') => {
  const blackList = ['of', 'the', 'a', 'an'];
  const strToArr = words(str);
  const modifiedArr = strToArr.map(s => includes(blackList, s) ? s : upperFirst(s));

  return modifiedArr.join(' ');
};

export const getDateText = ({ birthDate, deathDate }) => {
  if (birthDate && deathDate) {
    return `(${birthDate} - ${deathDate})`;
  }

  if (birthDate) {
    return `Born ${birthDate}`;
  }

  if (deathDate) {
    return `Died ${deathDate}`;
  }

  return '';
};

export const padYear = str => padStart(str, 4, '0');
