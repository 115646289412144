import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { isBrowser } from 'react-device-detect';

import NavigationBar from '../components/NavigationBar';
import EntityList from '../components/entityList';
import EntityDisplay from '../components/entityDisplay';
import MobileLayout from '../components/MobileLayout';

const BrowserEntities = ({ app, pageContext }) => {
  const { entityName, data, selectedEntity } = pageContext;
  const entities = get(data, 'data.allMarkdownRemark.nodes', []);

  return (
    <main className="entity-layout-container">
      <NavigationBar />

      <div className="entity-layout">
        <section className="entities">
          <EntityList
            entityName={entityName}
            entities={entities}
            selectedEntity={selectedEntity}
            hasInternet={app.hasInternet}
          />
        </section>
        <section className="details">
          <Container maxWidth="lg">
            <EntityDisplay
              entityData={selectedEntity}
              entityName={entityName}
              entities={entities}
            />
          </Container>
        </section>
        <section className="buffer" />
      </div>
    </main>
  );
};

BrowserEntities.propTypes = {
  selectedEntity: PropTypes.object,
  app: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

BrowserEntities.defaultProps = {
  selectedEntity: undefined,
};

const MobileEntities = ({ app, pageContext }) => {
  const { entityName, data, selectedEntity } = pageContext;
  const entities = get(data, 'data.allMarkdownRemark.nodes', []);

  return (
    <MobileLayout entityName={entityName}>
      <Container className="mobile-entity-list__container">
        <EntityList
          entityName={entityName}
          entities={entities}
          selectedEntity={selectedEntity}
          hasInternet={app.hasInternet}
        />
      </Container>
    </MobileLayout>
  );
};

MobileEntities.propTypes = {
  selectedEntity: PropTypes.object,
  app: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

MobileEntities.defaultProps = {
  selectedEntity: undefined,
};

const Entities = isBrowser ? BrowserEntities : MobileEntities;

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps)(Entities);
