import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ChromeReaderModeRoundedIcon from '@material-ui/icons/ChromeReaderModeRounded';
import MusicNoteIconRounded from '@material-ui/icons/MusicNoteRounded';
import StraightenIconRounded from '@material-ui/icons/StraightenRounded';
import CreateIconRounded from '@material-ui/icons/CreateRounded';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';

const MobileLayout = ({
  entityName,
  header,
  children,
  headerTitle,
}) => {
  const tabs = [
    { text: 'hymns', Icon: ChromeReaderModeRoundedIcon, to: '/hymns' },
    { text: 'composers', Icon: StraightenIconRounded, to: '/composers' },
    { text: 'authors', Icon: CreateIconRounded, to: '/authors' },
    { text: 'tunes', Icon: MusicNoteIconRounded, to: '/tunes' },
  ];

  return (
    <div className="mobile-layout">

      {header && (
        <AppBar position="static" color="secondary">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <Typography variant="h6">
              {headerTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      <div className="mobile-layout__content">
        {children}
      </div>

      <nav className="mobile-basenav__container">
        <ul className="u-list-style-none mobile-basenav">

          {tabs.map(({ text, Icon, to }, i) => (
            <li className="mobile-basenav__list" key={i}>
              <Icon
                style={{
                  fontSize: 25,
                  ...entityName === text ? { color: '#4CB5F5' } : { color: 'rgba(0, 0, 0, 0.54)' },
                }}
              />
              <div className="mobile-basenav__list-text">{text}</div>
              <Link className="u-abs-cover" to={to} />
            </li>
          ))}

        </ul>
      </nav>
    </div>
  );
};

MobileLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node), PropTypes.node,
  ]).isRequired,
  header: PropTypes.bool,
  headerTitle: PropTypes.string,
  entityName: PropTypes.string.isRequired,
};

MobileLayout.defaultProps = {
  header: false,
  headerTitle: undefined,
};

export default MobileLayout;
