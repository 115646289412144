import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Typography,
  Grid,
  IconButton,
  Button,
  Container,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { isMobile } from 'react-device-detect';

import { titlize } from '../../utils';
import AudioPlayer from '../AudioPlayer';
import { COMPOSERS, TUNES } from '../../globals';

const Display = ({ entityData, entities }) => {
  const {
    title,
    composer: composerName,
    bassVoice,
    altoVoice,
    combinedVoices,
    sopranoVoice,
    tenorVoice,
  } = get(entityData, 'frontmatter', {});

  const composer = entities.find(({ frontmatter }) =>
    frontmatter.name === composerName
    && frontmatter.type === COMPOSERS);

  const parts = [
    ...sopranoVoice ? [{ name: 'soprano', path: sopranoVoice }] : [],
    ...altoVoice ? [{ name: 'alto', path: altoVoice }] : [],
    ...tenorVoice ? [{ name: 'tenor', path: tenorVoice }] : [],
    ...bassVoice ? [{ name: 'bass', path: bassVoice }] : [],
    ...combinedVoices ? [{ name: 'combined voices', path: combinedVoices }] : [],
  ];

  return (
    <Container>
      <Grid container spacing={3} className="u-margin-b">
        <Grid item xs={1} className="u-relative">

          {isMobile && (
            <Link className="entity-back" to={`/${TUNES}`}>
              <IconButton>
                <ArrowBackIosRoundedIcon />
              </IconButton>
            </Link>
          )}

        </Grid>
        <Grid item xs={10}>
          <Typography
            component="h1"
            variant="h5"
            className="entity-title"
          >
            {titlize(title)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>

          {composer && (
            <div className="entity-hymn__link">
              <div className="u-txt--semibold u-margin-b--sm">Composer</div>
              <Link to={composer.frontmatter.slug} className="no-underline">
                <Button variant="outlined" color="primary">
                  {composer.frontmatter.name}
                </Button>
              </Link>
            </div>
          )}

          {parts.length > 0 && (
            <div className="entity-hymn__parts__container u-margin-t">
              <div className="u-txt--semibold u-margin-b--sm">Parts</div>
              <ul className="u-list-style-none entity-hymn__parts">

                {parts.map(({ name, path }, i) => (
                  <li className="entity-hymn__part" key={i}>
                    <AudioPlayer
                      title={titlize(name)}
                      src={path}
                    />
                  </li>
                ))}

              </ul>
            </div>
          )}

        </Grid>
      </Grid>
    </Container>
  );
};

Display.propTypes = {
  entityData: PropTypes.object,
  entities: PropTypes.array,
};

Display.defaultProps = {
  entityData: undefined,
  entities: [],
};

export default Display;
