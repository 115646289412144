import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { get } from 'lodash';
import { ListItem, ListItemText } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { isMobile } from 'react-device-detect';

import { titlize, getDateText } from '../../utils';

const AuthorListItem = ({ author, divider, selectedEntity }) => {
  const {
    name,
    birthDate,
    deathDate,
    slug,
    type,
  } = get(author, 'frontmatter');

  const secondaryText = getDateText({ birthDate, deathDate });
  const selectedEntityType = get(selectedEntity, 'frontmatter.type');
  const selectedEntitySlug = get(selectedEntity, 'frontmatter.slug');
  const isSelected = selectedEntityType === type && selectedEntitySlug === slug;

  return (
    <ListItem
      dense
      divider={divider}
      button
      className={`entity-list-item${isSelected ? ' entity-list-item--selected' : ''}`}
    >
      <ListItemText primary={titlize(name)} secondary={secondaryText} />

      {isMobile && <ChevronRightRoundedIcon className="u-txt--primary" />}

      <Link className="u-abs-cover" to={slug} />
    </ListItem>
  );
};

AuthorListItem.propTypes = {
  author: PropTypes.object.isRequired,
  divider: PropTypes.bool,
  selectedEntity: PropTypes.object,
};

AuthorListItem.defaultProps = {
  selectedEntity: {},
  divider: false,
};

export default AuthorListItem;
