import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import { Link } from 'gatsby';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';

class AudioPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.audioPlayer = {}; // To prevent fail on gatsby build
    this.state = {
      isPlaying: false,
    };
  }

  componentDidMount() {
    const { src } = this.props;
    this.audioPlayer = new Audio(src);
  }

  componentWillUnmount() {
    this.audioPlayer.pause();
  }

  onTogglePlay = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.audioPlayer.pause();
    } else {
      this.audioPlayer.play();
    }
    this.setState(state => ({ isPlaying: !state.isPlaying }));
  }

  onSkipBack = () => {
    this.audioPlayer.currentTime = 0;
  }

  render() {
    const { title, src } = this.props;
    const { isPlaying } = this.state;

    return (
      <Card>
        <div className="audio__details">

          {title && (
            <CardContent>
              <Typography
                variant="subtitle1"
                component="h5"
                className="audio__title"
              >
                {title}
              </Typography>
            </CardContent>
          )}

          <div className="audio__controls">
            <IconButton aria-label="volume" onClick={this.onSkipBack}>
              <SkipPreviousRoundedIcon color="primary" />
            </IconButton>
            <IconButton aria-label="play/pause" onClick={this.onTogglePlay}>

              {!isPlaying && (
                <PlayArrowIcon className="audio__play-icon" color="primary" />
              )}

              {isPlaying && (
                <PauseCircleFilledRoundedIcon className="audio__play-icon" color="primary" />
              )}

            </IconButton>
            <IconButton aria-label="download" className="audio__download-button">
              <CloudDownloadRoundedIcon color="primary" />
              <Link to={src} className="u-abs-cover" target="_blank" download />
            </IconButton>
          </div>
        </div>
      </Card>
    );
  }
}

AudioPlayer.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
};

AudioPlayer.defaultProps = {
  title: undefined,
};

export default AudioPlayer;
