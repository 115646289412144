import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import CloudOffRoundedIcon from '@material-ui/icons/CloudOffRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    alignItems: 'center',
  },
  cloudIcon: {
    marginRight: '20px',
  },
  text: {
    fontSize: '0.8rem',
  },
}));

const InfoPaper = ({ warning, entityName }) => {
  const classes = useStyles();
  const map = {
    offline: {
      title: 'Offline',
      icon: CloudOffRoundedIcon,
      text: 'Your network is unavailable. Check your data or wifi connection.',
    },
    noData: {
      icon: InfoRoundedIcon,
      title: `No ${entityName} available.`,
      text: 'We are regularly uploading new content so please come back soon.',
    },
  };

  const Icon = map[warning].icon;

  return (
    <Paper className={classes.root}>
      <Icon fontSize="large" color="primary" className={classes.cloudIcon} />
      <div>

        {map[warning].title && (
          <Typography variant="h6" component="h3">
            {map[warning].title}
          </Typography>
        )}

        {map[warning].text && (
          <Typography component="p" className={classes.text}>
            {map[warning].text}
          </Typography>
        )}

      </div>
    </Paper>
  );
};

InfoPaper.propTypes = {
  warning: PropTypes.string.isRequired,
  entityName: PropTypes.string,
};

InfoPaper.defaultProps = {
  entityName: 'data',
};

export default InfoPaper;
