import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MusicNoteRoundedIcon from '@material-ui/icons/MusicNoteRounded';
import { Typography } from '@material-ui/core';

import HymnDisplay from './Hymns';
import ComposerDisplay from './Composers';
import AuthorDisplay from './Authors';
import TuneDisplay from './Tunes';
import {
  HYMNS,
  COMPOSERS,
  AUTHORS,
  TUNES,
} from '../../globals';

const Display = (props) => {
  const { entityData, entityName } = props;
  const noEntityText = {
    hymns: 'Select a hymn',
    composers: 'Select a composer',
    authors: 'Select an author',
    tunes: 'Select a tune',
  };

  return (
    <>

      {!entityData && (
        <div className="display-view__no-entity">
          <MusicNoteRoundedIcon className="display-view__no-entity-icon" />
          <Typography component="p">{get(noEntityText, entityName, '')}</Typography>
        </div>
      )}

      {entityName === HYMNS && <HymnDisplay {...props} />}

      {entityName === COMPOSERS && <ComposerDisplay {...props} />}

      {entityName === AUTHORS && <AuthorDisplay {...props} />}

      {entityName === TUNES && <TuneDisplay {...props} />}

    </>
  );
};

Display.propTypes = {
  entityName: PropTypes.string.isRequired,
  entityData: PropTypes.object,
  entities: PropTypes.array,
};

Display.defaultProps = {
  entityData: undefined,
  entities: [],
};

export default Display;
